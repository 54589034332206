import styled from "styled-components";
import Layout from "../../components/Layout";
import React from "react";
import Card from "../../components/Card";
import { childVariants } from "../../framerOptions";

export default function NationalBilling() {
  return (
    <Layout>
      <Layout.Title>Facturación Nacional (Chile)</Layout.Title>
      <Container variants={childVariants}>
        <div style={{ display: "flex", gap: "2rem", justifyContent: "center" }}>
          <StyledCard>
            <ContactItem label="TISCHLER Y ASOCIADOS SPA" />
            <ContactItem label="Rut" value="76.384.378-5" />
            <ContactItem label="Giro" value="CONGRESO VETERINARIO" />
            <ContactItem label="Dirección" value="El Pehuén 7543, Peñalolen" />
          </StyledCard>
        </div>
      </Container>
    </Layout>
  );
}

const ContactItem = ({ label, value, icon }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        {icon && icon}
        <p style={{ fontWeight: 600, fontSize: "2rem", color: "#393939" }}>
          {label}
        </p>
      </div>
      <p style={{ color: "#393939" }}>{value}</p>
    </div>
  );
};

const Container = styled.div`
  background: #f2f5f8;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 4rem 0;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 2rem;
  padding-inline: 6rem;
`;
