import React from "react";
import styled from "styled-components";
import Layout from "../../../components/Layout";
import { Col, FlexboxGrid } from "rsuite";
import magazineImage from "../Events/assets/images/magazine.png";
import Button from "../../../components/Button";

export default function Magazine() {
  return (
    <Container>
      <Layout.Container maxWidth={1500} horizontalPadding={0}>
        <FlexboxGrid align="center" justify="start">
          <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>
            <Image src={magazineImage} />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>
            <TextContainer>
              <Title>Business Veterinary Magazine</Title>
              <p>
                Noticias, marketing, información tributaria, estrategia, ventas,
                actualidad, coaching, economía, finanzas, relaciones públicas,
                gestión, etc.
              </p>
              <p>
                Los invitamos a ser parte de este diferencial proyecto de
                comunicación, enseñanza y posicionamiento, destinados al
                crecimiento de la industria veterinaria..
              </p>
              <StyledButton
                external
                to="https://issuu.com/business_veterinary_magazine"
                target="_blank"
                color="#1C5EA4"
              >
                Ver ediciones
              </StyledButton>
            </TextContainer>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Layout.Container>
    </Container>
  );
}

const Container = styled.div`
  background: #fff;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding: 3.3rem 3.3rem;
`;

const Title = styled.h3`
  font-weight: 800;
  font-size: 3.3rem;
  color: #1c5ea4;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
`;

const StyledButton = styled(Button)`
  margin-top: 2.5rem;
`;
