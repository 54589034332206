import React from "react";
import Slider from "react-slick";
import styled, { keyframes } from "styled-components";
import { MD_BREAKPOINT, SM_BREAKPOINT } from "../../breakpoints";
import { useRequest } from "../../fetchboi/hooks";

export const LogoSlider = () => {
  const [partners, loading] = useRequest({
    url: "/congresses/partners",
  });

  const settings = {
    infinite: partners?.length >= 5,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 800,
    slidesToShow: Math.min(5, partners?.length || 5),
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(3, partners?.length || 3),
          infinite: partners?.length >= 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: Math.min(2, partners?.length || 2),
          infinite: partners?.length >= 2,
        },
      },
    ],
  };

  return (
    <Container>
      <Title>Nuestros partners comerciales</Title>
      {loading && (
        <div style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
          <LoadingItem />
          <LoadingItem />
          <LoadingItem />
          <LoadingItem />
          <LoadingItem />
        </div>
      )}
      {!loading && (
        <Slider {...settings}>
          {partners?.map((partner) => (
            <ImageItem
              key={partner.UUID}
              src={partner.PhotoURL}
              alt={partner.Name}
            />
          ))}
        </Slider>
      )}
    </Container>
  );
};

const Title = styled.h3`
  color: #393939;
  font-weight: 800;
  font-size: 3.7rem;
  line-height: inherit;
  text-align: center;
  margin-bottom: 5.8rem;

  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    font-size: 2.7rem;
  }

  @media screen and (max-width: ${SM_BREAKPOINT}em) {
    font-size: 2rem;
  }
`;

const Container = styled.div`
  overflow: hidden;
  background-color: #ffff;
  padding: 5.8rem 20px;

  .slick-slide {
    padding: 0 15px;
  }

  .slick-list {
    margin: 0 -15px;
  }
`;

const ImageItem = styled.img`
  width: 100%;
  height: 70px;
  object-fit: contain;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const skeletonKeyframes = keyframes`
  0% {
    background-position: -100px 0;
  }
  100% {
    background-position: calc(100px + 100%) 0;
  }
`;

const LoadingItem = styled.div`
  width: 300px;
  height: 100px;
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  background-color: #ffff;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  border-radius: 8px;
`;
