import styled, { keyframes } from "styled-components";
import Layout from "../../components/Layout";
import React from "react";
import InfoSubTitle from "../../components/Layout/InfoSubTitle";
import { useRequest } from "../../fetchboi/hooks";

export default function Faq() {
  const [faq, loading] = useRequest({ url: "/congresses/questions" });

  return (
    <Layout>
      <Layout.Title>Preguntas frecuentes</Layout.Title>
      <Container>
        {loading ? (
          <>
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
          </>
        ) : (
          faq?.map((faq, index) => (
            <FaqItem key={index} question={faq.Question} answer={faq.Answer} />
          ))
        )}
      </Container>
    </Layout>
  );
}

const Container = styled.div`
  background: #f2f5f8;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 4rem 0;
  white-space: pre-line;
`;

const FaqItem = ({ question, answer }) => {
  return <InfoSubTitle title={question} text={answer} />;
};

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

const Skeleton = styled.div`
  display: inline-block;
  height: ${(props) => props.height || "14px"};
  width: ${(props) => props.width || "100%"};
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 12px;
  margin-top: ${(props) => props.marginTop || "0"};
`;

const ItemSkeleton = styled(Skeleton)`
  margin-bottom: 16px;
  grid-column: span 1;
  margin: auto;
  height: 50px;
  max-width: 80%;
  display: block;
`;
