import React from "react";
import styled from "styled-components";
import { Icon } from "rsuite";
import { MD_BREAKPOINT, SM_BREAKPOINT } from "../../../../breakpoints";

export default function Card({ text, icon }) {
  return (
    <Container>
      <StyledIcon icon={icon} size="2x" color="#393939" />
      <Text>{text}</Text>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;

  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    margin: 1rem 0;
  }
  @media screen and (max-width: ${SM_BREAKPOINT}em) {
    margin: 0.5rem 0;
  }
`;

const StyledIcon = styled(Icon)`
  color: #393939;
  margin-right: 1rem;
`;

const Text = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #393939;
`;
