import React from "react";
import styled from "styled-components";
import { useRequest } from "../../fetchboi/hooks";

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropButton = styled.button`
  background: none;
  border: none;
  paddingvertical: 16px;
  cursor: pointer;
  color: #000000;
  font-size: 16px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  ${DropdownContainer}:hover & {
    display: block;
  }
`;

const DropdownLink = styled.a`
  color: #000000;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
    color: #000000;
  }
`;

export const CongressDropdown = () => {
  const [congresses] = useRequest({ url: `/congresses?IsActive=true` });
  return (
    <DropdownContainer>
      <DropButton>Congresos</DropButton>
      <DropdownContent>
        {congresses?.map((congress) => (
          <DropdownLink
            key={congress.Slug}
            href={`https://trazoevents.com/landing/${congress.Slug}/program`}
          >
            {congress.Name}
          </DropdownLink>
        ))}
      </DropdownContent>
    </DropdownContainer>
  );
};
