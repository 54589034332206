import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { childVariants } from "../../../framerOptions";
import SoonModal from "../SoonModal";
import VideoModal from "../VideoModal";

export default function EventCard({ url, image, name, date, video }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  const handleClick = () => {
    if (!url && !video) {
      setModalOpen(true);
    }
    if (!url && video) {
      setVideoModalOpen(true);
    }
  };

  return (
    <Container
      variants={childVariants}
      whileHover={{ scale: 1.05, backgroundColor: "#1C5EA4" }}
      whileTap={{ scale: 0.9 }}
    >
      <a
        href={url}
        onClick={handleClick}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image src={image} />
        <Name>{name}</Name>
        <Date>{date}</Date>
      </a>
      <SoonModal show={modalOpen} onClose={() => setModalOpen(false)} />
      <VideoModal
        url={video}
        show={videoModalOpen}
        onClose={() => setVideoModalOpen(false)}
      />
    </Container>
  );
}

const Container = styled(motion.div)`
  background: #1c5ea5;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 3.2rem;

  a {
    display: block;
    padding: 1rem;
    text-decoration: none !important;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 17rem;
  object-fit: cover;
  overflow: hidden;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
`;

const Name = styled.h5`
  font-size: 1.8rem;
  font-weight: 800;
  text-align: center;
  color: #fff;
  margin-top: 0.6rem;
  height: 48px;
`;

const Date = styled.p`
  font-size: 1.6rem;
  text-align: center;
  color: #fff;
  margin-bottom: 0.9rem;
`;
