import React from "react";
import styled from "styled-components";
import Container from "./Container";
import Head from "./Head";
import Header from "./Header";
import Title from "./Title";
import SubTitle from "./SubTitle";
import Loader from "./Loader";
import Footer from "./Footer";

export default function Layout({ children, showPaymentButton }) {
  return (
    <LayoutContainer>
      <Head />
      <Header showButton={showPaymentButton} />
      <Body>{children}</Body>
      <Footer />
    </LayoutContainer>
  );
}

const LayoutContainer = styled.div``;

const Body = styled.div``;

Layout.Container = Container;
Layout.Head = Head;
Layout.Title = Title;
Layout.SubTitle = SubTitle;
Layout.Loader = Loader;
