import React from "react";
import styled from "styled-components";
import Layout from "../../components/Layout";
import Hero from "./Hero";
import Events from "./Events";
import Magazine from "./Magazine";
import Download from "./Download";
import InfoModal from "./InfoModal";
import VideoLink from "./VideoLink";
import { Videos } from "./Videos";
import { LogoSlider } from "./LogoSlider";

export default function Home() {
  return (
    <Layout showPaymentButton>
      <Container>
        {/* <VideoLink/> */}
        <Hero />
        <LogoSlider />
        <Events />
        <Download />
        {/* <Magazine /> */}
        <Videos />
      </Container>
    </Layout>
  );
}

const Container = styled.div``;
