import React, { useMemo } from "react";
import Axios from "axios";
import { Route, BrowserRouter } from "react-router-dom";
import ErrorBoundary from "react-error-boundary";
import { hot } from "react-hot-loader";
import Home from "./pages/Home";
import PaymentMethod from "./pages/PaymentMethod";
import ChileanMethod from "./pages/ChileanMethod";
import ForeignerMethod from "./pages/ForeignerMethod";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentFailure from "./pages/PaymentFailure";
import PaymentPaypal from "./pages/PaymentPaypal";
import Cartagena2022 from "./pages/Cartagena2022";
import Colchagua2022 from "./pages/Colchagua2022";
import FetchboiProvider from "./fetchboi/context";
import { apiURL } from "./config";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Faq from "./pages/Faq";
import NationalBilling from "./pages/NationalBilling";
import InternationalBilling from "./pages/InternationalBilling";

function App() {
  const axios = useMemo(
    () =>
      Axios.create({
        baseURL: apiURL,
      }),
    []
  );

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <FetchboiProvider axios={axios} extract="Data">
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/payment/method" exact>
            <PaymentMethod />
          </Route>
          <Route path="/payment/chilean" exact>
            <ChileanMethod />
          </Route>
          <Route path="/payment/foreigner" exact>
            <ForeignerMethod />
          </Route>
          <Route path="/payment/success" exact>
            <PaymentSuccess />
          </Route>
          <Route path="/payment/failure" exact>
            <PaymentFailure />
          </Route>
          <Route path="/payment/paypal" exact>
            <PaymentPaypal />
          </Route>
          <Route path="/cartagena2022" exact>
            <Cartagena2022 />
          </Route>
          <Route path="/colchagua2022" exact>
            <Colchagua2022 />
          </Route>
          <Route path="/about-us" exact>
            <AboutUs />
          </Route>
          <Route path="/national-billing" exact>
            <NationalBilling />
          </Route>
          <Route path="/international-billing" exact>
            <InternationalBilling />
          </Route>
          <Route path="/faq" exact>
            <Faq />
          </Route>
          <Route path="/contact" exact>
            <Contact />
          </Route>
        </FetchboiProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default process.env.NODE_ENV === "development" ? hot(module)(App) : App;
