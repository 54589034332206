import React from "react";
import styled from "styled-components";
import Layout from "../../../components/Layout";
import { MD_BREAKPOINT, SM_BREAKPOINT } from "../../../breakpoints";
import { parentVariants } from "../../../framerOptions";
import { motion } from "framer-motion";
import { BsWhatsapp } from "react-icons/bs";
import { EventGrid } from "./EventGrid";
export default function Events() {
  return (
    <Container>
      <Layout.Container maxWidth={650}>
        <Title>Selecciona la experiencia que quieres conocer</Title>
      </Layout.Container>
      <Layout.Container>
        <motion.div
          variants={parentVariants}
          initial="hidden"
          animate="visible"
        >
          <EventGrid />
        </motion.div>
      </Layout.Container>
      <a
        href="https://wa.me/56996384517"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <BsWhatsapp className="whatsapp-icon" />
      </a>
    </Container>
  );
}

const Container = styled.div`
  background: #f2f5f8;
  padding: 5.8rem 0 10rem;
`;

const Title = styled.h3`
  color: #393939;
  font-weight: 800;
  font-size: 3.7rem;
  line-height: inherit;
  text-align: center;
  margin-bottom: 5.8rem;

  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    font-size: 2.7rem;
  }

  @media screen and (max-width: ${SM_BREAKPOINT}em) {
    font-size: 2rem;
  }
`;
