import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import Layout from "../../../components/Layout";
import { MD_BREAKPOINT, SM_BREAKPOINT } from "../../../breakpoints";
import cartagenaImage from "./assets/images/cartagena.jpeg";
import colchaguaImage from "./assets/images/colchagua.jpg";

export default function Hero() {
  return (
    <Container>
      <Slider infinite autoplay autoplaySpeed={4000} speed={800}>
        <Image src={cartagenaImage} />
        <Image src={colchaguaImage} />
      </Slider>

      <Backdrop />
      <TextContainer>
        <Layout.Container maxWidth={900}>
          <Text>Más que un congreso, una experiencia empresarial.</Text>
        </Layout.Container>
      </TextContainer>
    </Container>
  );
}

const Container = styled.div`
  overflow: hidden;
  position: relative;
`;

const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
`;

const Image = styled.img`
  width: 100%;
  height: 40rem;
  object-fit: cover;
`;

const TextContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.h1`
  font-weight: 800;
  font-size: 3.7rem;
  text-align: center;
  color: #fff;

  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    font-size: 4rem;
  }

  @media screen and (max-width: ${SM_BREAKPOINT}em) {
    font-size: 3rem;
  }
`;
