import React from "react";
import styled from "styled-components";
import { Icon } from "rsuite";
import Layout from "./index";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom/cjs/react-router-dom";

export default function Footer() {
  return (
    <Container>
      <Layout.Container>
        <FooterContent>
          <FooterSection>
            <FooterTitleLinkExternal
              target="_blank"
              href="https://docs.google.com/spreadsheets/d/1NfYCrhZX9W-h3Q7moN6uloF3Mrlov8WL6ESiQJ9raaI/edit?gid=0#gid=0"
            >
              Descargar formato de becados
            </FooterTitleLinkExternal>
            <FooterTitleLink to="/payment/method">
              Formas de pago
            </FooterTitleLink>
          </FooterSection>

          <FooterSection>
            <FooterTitle>Síguenos en:</FooterTitle>
            <SocialIcons>
              <FacebookLink
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.9 }}
                href="https://facebook.com/congresoveterinariochile/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon size="2x" icon="facebook-official" />
                <SocialText>Facebook</SocialText>
              </FacebookLink>

              <YoutubeLink
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.9 }}
                href="https://youtube.com/channel/UCj5fkefs4gFOZtwCTdsVTHQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon size="2x" icon="youtube" />
                <SocialText>YouTube</SocialText>
              </YoutubeLink>

              <InstagramLink
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.9 }}
                href="https://instagram.com/congresoveterinario.cl?igshid=YmMyMTA2M2Y="
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon size="2x" icon="instagram" />
                <SocialText>Instagram</SocialText>
              </InstagramLink>
            </SocialIcons>
          </FooterSection>

          <FooterSection>
            <FooterTitle>Facturación</FooterTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <FooterTitleLinkLight to="/national-billing">
                Nacional
              </FooterTitleLinkLight>
              <FooterTitleLinkLight to="/international-billing">
                Internacional
              </FooterTitleLinkLight>
            </div>
          </FooterSection>

          <FooterSection>
            <FooterTitle>Dirección</FooterTitle>
            <FooterText>El Pehuén 7543, Peñalolen</FooterText>
          </FooterSection>
        </FooterContent>
      </Layout.Container>
    </Container>
  );
}

const Container = styled.div`
  background: #0c2845;
  padding: 2rem 0;
`;

const FooterContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  padding: 2rem 0;
`;

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FooterTitle = styled.h3`
  color: #fff;
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
`;

const FooterLink = styled.a`
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const FooterText = styled.p`
  color: #fff;
  margin: 0;
`;

const SocialIcons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FacebookLink = styled(motion.a)`
  display: flex;
  align-items: center;
  color: #fff !important;
`;

const InstagramLink = styled(motion.a)`
  display: flex;
  align-items: center;
  color: #fff !important;
`;

const YoutubeLink = styled(motion.a)`
  display: flex;
  align-items: center;
  color: #fff !important;
`;

const SocialText = styled.span`
  color: #fff;
  margin-left: 0.5rem;
  font-size: 1.4rem;
`;

const FooterTitleLink = styled(NavLink)`
  color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 0.5rem;
  line-height: 42px;
`;

const FooterTitleLinkLight = styled(NavLink)`
  color: #fff;
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 0.5rem;
  line-height: 42px;
`;

const FooterTitleLinkExternal = styled.a`
  color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 0.5rem;
  line-height: 42px;
`;

const BillingTitle = styled.p`
  color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
`;
