import React from "react";
import styled from "styled-components";
import LayoutContainer from "./Container";
import { MD_BREAKPOINT } from "../../breakpoints";

export default function Text({ children }) {
  return (
    <Container>
      <StyledLayoutContainer>
        <Description>{children}</Description>
      </StyledLayoutContainer>
    </Container>
  );
}

const Container = styled.div`
  padding: 2rem 0;
`;

const StyledLayoutContainer = styled(LayoutContainer)`
  height: 100%;
  display: flex;
  align-items: center;
`;

const Description = styled.p`
  font-weight: 100;
  font-size: 2rem;
  color: #393939;

  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    font-size: 1.5rem;
  }
`;
