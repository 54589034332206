import React from "react";

export const Phone = ({ size = 24, ...rest }) => {
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      >
        <rect width="12.5" height="18.5" x="5.75" y="2.75" rx="3" />
        <path d="M11 17.75h2" />
      </g>
    </svg>
  );
};
