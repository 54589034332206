import React from "react";
import Container from "../../components/Layout/Container";
import styled from "styled-components";
import { MD_BREAKPOINT, SM_BREAKPOINT } from "../../breakpoints";
import { useRequest } from "../../fetchboi/hooks";

export const Videos = () => {
  const [videos, loading] = useRequest({
    url: "/congresses/videos",
  });

  return (
    <section style={{ backgroundColor: "#f2f5f8" }}>
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBlock: "4rem",
          }}
        >
          <Title>Algunos videos</Title>
          <VideoContainer>
            {!loading &&
              videos?.map((video) => (
                <VideoItem
                  key={video.UUID}
                  id={video.VideoID}
                  name={video.Name}
                />
              ))}
          </VideoContainer>
        </div>
      </Container>
    </section>
  );
};

const VideoItem = ({ id, name }) => {
  return (
    <iframe
      className="responsive-video"
      height="350"
      src={`https://www.youtube.com/embed/${id}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={name}
      style={{
        width: "100%",
        objectFit: "cover",
      }}
    />
  );
};

const VideoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  @media (max-width: ${MD_BREAKPOINT}em) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Title = styled.h3`
  color: #393939;
  font-weight: 800;
  font-size: 3.7rem;
  line-height: inherit;
  text-align: center;
  margin-bottom: 5.8rem;

  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    font-size: 2.7rem;
  }

  @media screen and (max-width: ${SM_BREAKPOINT}em) {
    font-size: 2rem;
  }
`;
