import styled from "styled-components";
import Layout from "../../components/Layout";
import React from "react";
import Card from "../../components/Card";
import { childVariants } from "../../framerOptions";
import { Envelope } from "../../components/Icon/icons/Envelope";
import { Phone } from "../../components/Icon/icons/Phone";
import { Link } from "react-router-dom";
import { Whatsapp } from "../../components/Icon/icons/Whatsapp";

export default function Contact() {
  return (
    <Layout>
      <Layout.Title>Contacto</Layout.Title>
      <Container variants={childVariants}>
        <div style={{ display: "flex", gap: "2rem", justifyContent: "center" }}>
          <StyledCard>
            <ContactItem
              label="Email"
              value="contacto@congresoveterinario.cl"
              icon={<Envelope />}
            />
            <ContactItem
              label="Teléfono"
              value="+56 9 9638 4517"
              icon={<Phone />}
            />
            <WhatsappItem
              label="WhatsApp"
              value="+56 9 9638 4517"
              icon={<Whatsapp style={{ color: "#393939" }} />}
            />
          </StyledCard>
        </div>
      </Container>
    </Layout>
  );
}

const ContactItem = ({ label, value, icon }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        {icon}
        <p style={{ fontWeight: 600, fontSize: "2rem", color: "#393939" }}>
          {label}
        </p>
      </div>
      <p style={{ color: "#393939" }}>{value}</p>
    </div>
  );
};

const WhatsappItem = ({ label, value, icon }) => {
  return (
    <a
      href="https://wa.me/56996384517"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          cursor: "pointer",
          textDecoration: "none",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          {icon}
          <p style={{ fontWeight: 600, fontSize: "2rem", color: "#393939" }}>
            {label}
          </p>
        </div>
        <p style={{ color: "#393939" }}>{value}</p>
      </div>
    </a>
  );
};

const Container = styled.div`
  background: #f2f5f8;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 4rem 0;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 2rem;
  padding-inline: 6rem;
`;

const Title = styled.h4`
  font-weight: 800;
  font-size: 2rem;
  color: #393939;
  text-align: center;
  margin-bottom: 1rem;
`;
