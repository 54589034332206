import styled, { keyframes } from "styled-components";
import { useRequest } from "../../../fetchboi/hooks";
import React from "react";
import { MD_BREAKPOINT, SM_BREAKPOINT } from "../../../breakpoints";
import EventCard from "../EventCard";

export const EventGrid = () => {
  const [congresses, loading] = useRequest({
    url: "/congresses?IsActive=true",
  });

  const sortedCongresses = congresses?.sort(
    (a, b) => new Date(a.StartsAt) - new Date(b.StartsAt)
  );

  return (
    <Container>
      {loading ? (
        <>
          <EventSkeleton />
          <EventSkeleton />
          <EventSkeleton />
          <EventSkeleton />
          <EventSkeleton />
          <EventSkeleton />
        </>
      ) : (
        sortedCongresses?.map((congress) => (
          <EventCard
            key={congress.Slug}
            url={`https://trazoevents.com/landing/${congress.Slug}/program`}
            name={congress.Name}
            date={congress.DateLine}
            image={congress.LandingBannerURL}
          />
        ))
      )}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  @media (max-width: ${MD_BREAKPOINT}em) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${SM_BREAKPOINT}em) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

const Skeleton = styled.div`
  display: inline-block;
  height: ${(props) => props.height || "14px"};
  width: ${(props) => props.width || "100%"};
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 12px;
  margin-top: ${(props) => props.marginTop || "0"};
`;

const EventSkeleton = styled(Skeleton)`
  margin-bottom: 16px;
  grid-column: span 1;
  margin: auto;
  height: 275px;
  max-width: 360px;
  display: block;
`;
