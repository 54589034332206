import React from "react";
import styled from "styled-components";
import LayoutContainer from "./Container";
import { MD_BREAKPOINT } from "../../breakpoints";
import Text from "./Text";

export default function SubTitle({ title, description, text }) {
  return (
    <Container>
      <StyledLayoutContainer>
        <ContentContainer>
          <Title>{title}</Title>
          {description && <Description>{description}</Description>}
        </ContentContainer>
      </StyledLayoutContainer>
      {text && <Text>{text}</Text>}
    </Container>
  );
}

const Container = styled.div`
  padding: 6.1rem 0;
`;

const StyledLayoutContainer = styled(LayoutContainer)`
  height: 100%;
  display: flex;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left: 0.8rem solid #1c5ea4;
  padding-left: 1.3rem;
  min-height: 6rem;
`;

const Title = styled.h3`
  font-weight: 800;
  font-size: 3.7rem;
  color: #393939;

  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    font-size: 3rem;
  }
`;

const Description = styled.p`
  margin-top: 1rem;
  font-weight: 100;
  font-size: 2rem;
  color: #393939;

  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    font-size: 1.5rem;
  }
`;
