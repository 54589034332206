import React from "react";
import styled from "styled-components";
import Layout from "../../components/Layout";
import InfoSubTitle from "../../components/Layout/InfoSubTitle";
import Text from "../../components/Layout/Text";
import { Staff } from "./components/Staff";

export default function AboutUs() {
  return (
    <Layout>
      <Layout.Title>Sobre nosotros</Layout.Title>
      <Container>
        <InfoSubTitle
          title="¿Quiénes somos?"
          text="Bienvenidos a Congreso Veterinario Business, un espacio de encuentro fundamental para profesionales, empresas y líderes del sector veterinario. Desde 2015, nos hemos comprometido a proporcionar oportunidades únicas para la conexión, el intercambio de conocimientos y el establecimiento de relaciones comerciales clave para la industria."
        />

        <InfoSubTitle
          title="Nuestra Trayectoria"
          text="Desde nuestro inicio en 2015, Congreso Veterinario Business se ha consolidado como una plataforma líder en la industria, brindando experiencias educativas y de networking de alta calidad que han beneficiado a miles de participantes y empresas en el sector veterinario."
        />
        <InfoSubTitle
          title="Nuestros Valores"
          text={
            <ul
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <li>
                <b>Excelencia Educativa:</b> Nos comprometemos a ofrecer
                programas educativos de la más alta calidad, diseñados para
                inspirar, informar y capacitar a nuestros asistentes.
              </li>
              <li>
                <b>Colaboración y Networking:</b> Creemos en la importancia de
                crear espacios donde colegas, expertos y líderes de la industria
                puedan conectarse, compartir ideas y colaborar en proyectos
                innovadores.
              </li>
              <li>
                <b>Visibilidad y Promoción:</b> Valoramos la importancia de
                destacar la presencia de nuestros colaboradores y
                patrocinadores, ofreciendo oportunidades de visibilidad de marca
                en todos nuestros eventos y materiales promocionales.
              </li>
            </ul>
          }
        />

        <InfoSubTitle
          title="Nuestra Misión"
          text="Nuestra misión es contribuir al crecimiento y desarrollo continuo de la industria veterinaria, ofreciendo experiencias de aprendizaje significativas, oportunidades de networking y plataformas de promoción que impulsen el éxito de nuestros participantes y aliados comerciales."
        />

        <InfoSubTitle
          title="¿Por qué Elegirnos?"
          text={
            <ul
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <li>
                <b>Programa Educativo Integral:</b> Acceso completo a
                conferencias, talleres y sesiones educativas diseñadas para
                satisfacer las necesidades de la comunidad veterinaria.
              </li>
              <li>
                <b>Networking Exclusivo:</b> Oportunidades para interactuar con
                líderes de la industria y colegas, facilitando la generación de
                nuevas ideas y colaboraciones comerciales.
              </li>
              <li>
                <b>Visibilidad de Marca:</b> Promoción de la presencia de su
                empresa en nuestros materiales promocionales, sitio web y redes
                sociales, asegurando una mayor visibilidad ante una audiencia
                altamente relevante.
              </li>
              <li>
                <b>Oportunidades de Patrocinio:</b> Amplíe su participación
                mediante el patrocinio de sesiones específicas, actividades
                sociales o espacios de exhibición, maximizando la exposición de
                su marca y productos.
              </li>
            </ul>
          }
        />

        <Text>
          En Congreso Veterinario Business, nos comprometemos a ser su aliado
          estratégico en el crecimiento y éxito de su empresa en la industria
          veterinaria.
        </Text>
        <Staff />
      </Container>
    </Layout>
  );
}

const Container = styled.div`
  background: #f2f5f8;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 4rem 0;
`;
