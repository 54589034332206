import React, { useRef, useState } from "react";
import styled from "styled-components";
import {
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  InputNumber,
  Schema,
} from "rsuite";
import Button from "../../../components/Button";

const { NumberType } = Schema.Types;

const model = Schema.Model({
  amount: NumberType()
    .min(0.01, "Debe ingresar un número positivo")
    .isRequired("Este campo es requerido"),
});

export default function AmountForm({ onSubmit }) {
  const [amount, setAmount] = useState();
  const [error, setError] = useState();

  const form = useRef(null);

  const handleSubmit = () => {
    if (form.current.check()) {
      onSubmit && onSubmit(amount);
    }
  };

  return (
    <Container>
      <Title>Monto a pagar en USD</Title>
      <Text>Ingrese el monto acordado con la organización del congreso.</Text>
      <Form
        ref={form}
        onChange={(formValue) => {
          console.log(formValue);
          setAmount(formValue?.amount);
        }}
        onCheck={(formError) => {
          console.log(formError, "formError");
          setError(formError?.amount);
        }}
        formValue={{ amount }}
        model={model}
      >
        <StyledFormGroup className={error ? "has-error" : ""}>
          <FormControl
            name="amount"
            placeholder="Ej: 1290"
            accepter={InputNumber}
            errorMessage={error}
          />
        </StyledFormGroup>
        <Button color="#1C5EA4" onClick={handleSubmit}>
          Ir al pago
        </Button>
      </Form>
    </Container>
  );
}

const Container = styled.div`
  text-align: center;
`;

const Title = styled.h4`
  font-size: 2rem;
  font-weight: 600;
`;

const Text = styled.p`
  font-size: 2rem;
  margin-bottom: 2rem;
`;

const StyledFormGroup = styled(FormGroup)`
  text-align: center;
  .rs-form-control-wrapper,
  .rs-input-group.rs-input-number {
    min-width: inherit !important;
    max-width: 300px !important;
    width: 100% !important;
  }
`;
