import React from "react";
import styled from "styled-components";
import Layout from "../../../components/Layout";
import { LG_BREAKPOINT } from "../../../breakpoints";
import Buttons from "./Buttons";
import Features from "./Features";
import Title from "./Title";

export default function Download() {
  return (
    <Container>
      <Layout.Container maxWidth={1200} style={{ paddingBottom: "2rem" }}>
        <TopSectionContainer>
          <Title />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 16,
            }}
          >
            <p style={{ fontWeight: 600, fontSize: 34, color: "#1C5EA4" }}>
              Potencia tu experiencia
            </p>
            <p style={{ fontWeight: 400, fontSize: 26, color: "#393939" }}>
              Descargando nuestra aplicación móvil
            </p>
            <Text>
              Ten en tus manos toda la programación y actividades que tendremos
              disponibles durante el Punta Cana Business Veterinary 2023
            </Text>
            <Features />
            <Buttons />
          </div>
        </TopSectionContainer>
      </Layout.Container>
      <Layout.Container maxWidth={1200}></Layout.Container>
    </Container>
  );
}

const Text = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #393939;
  max-width: 700px;
`;

const Container = styled.div`
  background: #fff;
  padding: 2.1em;
`;

const TopSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${LG_BREAKPOINT}em) {
    flex-direction: column;
  }
`;
