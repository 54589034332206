import React from "react";
import styled from "styled-components";
import LayoutContainer from "../../../components/Layout/Container";
import { MD_BREAKPOINT } from "../../../breakpoints";
import { useRequest } from "../../../fetchboi/hooks";

export const Staff = () => {
  const [team] = useRequest({ url: "/congresses/teams" });

  return (
    <Container>
      <StyledLayoutContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "4rem",
            justifyContent: "center",
            alignItems: "start",
            flexWrap: "wrap",
          }}
        >
          {team?.map((member) => (
            <StaffMember key={member.FirstName} member={member} />
          ))}
        </div>
      </StyledLayoutContainer>
    </Container>
  );
};

const StaffMember = ({ member }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        alignItems: "center",
        justifyContent: "start",
        flex: 1,
      }}
    >
      <img
        src={member.PhotoURL}
        alt={member.FirstName}
        style={{
          borderRadius: "100%",
          width: "100px",
          height: "100px",
          objectFit: "cover",
        }}
      />
      <div style={{ textAlign: "center" }}>
        <h3>{member.FirstName}</h3>
        <p>{member.Position}</p>
      </div>
    </div>
  );
};

const Container = styled.div`
  padding: 2rem 0;
`;

const StyledLayoutContainer = styled(LayoutContainer)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Description = styled.p`
  font-weight: 100;
  font-size: 2rem;
  color: #393939;
  text-align: center;

  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    font-size: 1.5rem;
  }
`;
