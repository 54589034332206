import React from "react";
import styled from "styled-components";
import LayoutContainer from "./Container";
import dotsImage from "../../assets/images/dots.png";
import { MD_BREAKPOINT } from "../../breakpoints";

export default function Title({ children }) {
  return (
    <Container>
      <StyledLayoutContainer>
        <Text>{children}</Text>
        <Dots />
      </StyledLayoutContainer>
    </Container>
  );
}

const Container = styled.div`
  background: #1c5ea4;
  height: 25.4rem;
`;

const StyledLayoutContainer = styled(LayoutContainer)`
  height: 100%;
  display: flex;
  align-items: center;
`;

const Text = styled.h1`
  color: #fff;
  font-weight: 800;
  font-size: 4.7rem;

  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    font-size: 3rem;
  }
`;

const Dots = styled.div`
  position: absolute;
  height: 16rem;
  width: 16rem;
  background-image: url("${dotsImage}");
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateX(-45%);
`;
